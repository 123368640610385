import React, { forwardRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Simple = forwardRef(({ children, className = '', settings }, ref) => (
  <div className={className}>
    <Slider ref={ref} {...settings}>
      {children}
    </Slider>
  </div>
))

Simple.displayName = 'Simple'
Simple.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  className: PropTypes.string,
  settings: PropTypes.object
}

export default Simple
