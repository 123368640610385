const links = [
  {
    name: 'terms',
    href: 'https://www.mlytics.com/terms/',
    clickID: ''
  },
  {
    name: 'privacy',
    href: 'https://www.mlytics.com/privacy',
    clickID: ''
  },
  {
    name: 'login',
    href: 'https://portal.mlytics.com/login/',
    clickID: ''
  }
]

const slideImages = [
  {
    imageSrc: 'https://static.mlytics.com/images/website/signup_hero_slide_1.webp',
    imageAlt: 'Signup slide image 1'
  },
  {
    imageSrc: 'https://static.mlytics.com/images/website/signup_hero_slide_2.webp',
    imageAlt: 'Signup slide image 2'
  }
]

export { links, slideImages }
