import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import Icon from 'components/icon'
import SimpleSlider from 'components/carousel/simple'
import Captcha from 'components/captcha/index'
import LangSwitcher from 'components/langSwitcher/popover'

import { getPortalHost } from 'utils'

import { FormControl, IconButton, InputAdornment, TextField, Divider } from '@mui/material'

import { links, slideImages } from '/static/data/signup.static'

import { sendDataLayerEvent } from 'utils'

const SignupForm = ({ actionProp: { setMessageDialogOpen, setErrorMessageI18n } }) => {
  const { t, i18n } = useTranslation()

  const { screenM } = useBreakpoint()

  const CustomTransComponents = ({ links = [] }) => ({
    href: (
      <React.Fragment>
        {links?.map((link, linkIndex) => (
          <a
            key={linkIndex}
            id={link.clickID}
            href={link.href}
            className="underline cursor-pointer text-blue-200"
            target="_blank"
            rel="noreferrer"
          >
            {t(link.text)}
          </a>
        ))}
      </React.Fragment>
    )
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isNeedCaptcha, setIsNeedCaptcha] = useState(false)
  const [isReGetCaptcha, setIsReGetCaptcha] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const captchaValidation = isNeedCaptcha ? yup.string().required('Field is required') : yup.string()

  const schema = yup.object().shape({
    firstName: yup.string().required(t('errorMessages.isRequired', { field: t('signUpPage.form.0') })),
    lastName: yup.string().required(t('errorMessages.isRequired', { field: t('signUpPage.form.1') })),
    email: yup
      .string()
      .required(t('errorMessages.isRequired', { field: t('signUpPage.form.2') }))
      .email(t('errorMessages.invalidFormat', { field: t('signUpPage.form.2') })),
    password: yup
      .string()
      .required(t('errorMessages.isRequired', { field: t('signUpPage.form.3') }))
      .min(8, t('errorMessages.isMinCharacters', { field: t('signUpPage.form.3'), count: 8 }))
      .matches(/[A-Z]/, t('errorMessages.isAtLeastOneUppercaseLetter', { field: t('signUpPage.form.3') }))
      .matches(/[a-z]/, t('errorMessages.isAtLeastOneLowercaseLetter', { field: t('signUpPage.form.3') }))
      .matches(/\d/, t('errorMessages.isAtLeastOneNumber', { field: t('signUpPage.form.3') })),
    captchaInput: captchaValidation
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema)
  })
  const password = watch('password', '')

  // 使用 useState 跟踪密碼強度規則
  const [strengthRules, setStrengthRules] = useState({
    hasMinLength: false,
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false
  })

  const validatePasswordStrength = () => {
    // 密碼強度檢查邏輯
    const hasMinLength = password.length >= 8
    const hasUppercase = /[A-Z]/.test(password)
    const hasLowercase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)

    // 更新密碼強度規則的有效性
    setStrengthRules({
      hasMinLength,
      hasNumber,
      hasLowercase,
      hasUppercase
    })
  }

  const handleSignup = async (data) => {
    setIsActionLoading(true)
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL
      const hubspotToken = Cookies.get('hubspotutk') || null

      const payload = {
        action: 'commonRegister',
        ...data,
        hubspotToken
      }
      if (isNeedCaptcha) payload.captchaToken = captchaToken

      const response = await fetch(`${apiUrl}/auth/v2/auth/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }

      const { customerId, organizationId, jwtToken } = json.data

      Cookies.set('jwtToken', jwtToken, { secure: true })
      Cookies.set('customerId', customerId, { secure: true })
      Cookies.set('organizationId', organizationId, { secure: true })

      // send GTM event
      const eventPayload = {
        event: 'sign_up',
        method: 'email',
        user_id: organizationId,
        email: data.email
      }

      sendDataLayerEvent(eventPayload)

      navigate('/add-payment/')
    } catch (error) {
      if (error?.response?.code === 1521) {
        setIsNeedCaptcha(true)
        setIsReGetCaptcha(true)
      } else {
        const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
          ? `errorMessages.${error?.response?.code}`
          : 'errorMessages.signUp'
        setErrorMessageI18n(errorMessage)
        setMessageDialogOpen(true)
      }
    } finally {
      setIsActionLoading(false)
    }
  }

  const handleSignupWithGoogle = () => {
    Cookies.remove('jwtToken') // clear jwtToken 才能跑callback error
    const googleOauthConfig = {
      scope: 'email profile openid',
      prompt: 'consent',
      responseType: 'token id_token',
      state: {
        from: 'signup'
      }
    }
    const { scope, prompt, responseType } = googleOauthConfig
    const state = JSON.stringify(googleOauthConfig.state)

    const nonce = `
      ${Math.random().toString(36).substring(2, 15)}
      ${Math.random().toString(36).substring(2, 15)}
    `
    window.location.href = window.encodeURI(
      `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?
        client_id=${process.env.GATSBY_OAUTH_GOOGLE_CLIENT_ID}&
        scope=${scope}&
        state=${state}&
        prompt=${prompt}&
        response_type=${responseType}&
        ux_mode=redirect&
        redirect_uri=${process.env.GATSBY_OAUTH_GOOGLE_CALLBACK}&
        nonce=${nonce}&
        include_granted_scopes=true`
        .replace(/\n/g, '')
        .replace(/\s{2,}/g, '')
    )
  }

  useEffect(() => {
    validatePasswordStrength()
  }, [password])

  return (
    <AuthLayout hasNav={false} hasFooter={false}>
      <React.Fragment>
        {/* Signup Form */}
        <form onSubmit={handleSubmit(handleSignup)} className="flex items-center">
          <FormControl
            sx={{
              margin: '0 auto',
              width: screenM ? '400px' : '100%',
              maxWidth: '100%'
            }}
          >
            {/* First name */}
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  label="First name"
                  variant="outlined"
                  size="small"
                  sx={{
                    mb: errors.firstName ? 1 : 3
                  }}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />

            {/* Last name */}
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  label="Last name"
                  variant="outlined"
                  size="small"
                  sx={{
                    mb: errors.lastName ? 1 : 3
                  }}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
            {/* Email */}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  label="Email"
                  variant="outlined"
                  type={'email'}
                  size="small"
                  sx={{
                    mb: errors.email ? 1 : 3
                  }}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            {/* Password */}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          <Icon
                            fontSize="20px"
                            name={showPassword ? 'visibility_off' : 'visibility'}
                            className="text-gray-700"
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    mb: 1
                  }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            {/* Password Strength */}
            <p className="mb-1 text-gray-700">
              <Trans i18nKey="signUpPage.passwordStrengthTitle">
                <b></b>
              </Trans>
            </p>
            <div className="mb-3 bg-gray-400 bg-opacity-5 p-3 grid grid-cols-1 md:grid-cols-2 rounded-xl">
              {Object.keys(strengthRules).map((ruleKey, ruleKeyIndex) => {
                return (
                  <div key={ruleKeyIndex}>
                    <Icon
                      className={`mr-1 basis-[32px] py-1 ${strengthRules[ruleKey] ? 'text-blue-200' : 'text-gray-400'}`}
                      fontSize="20px"
                      name="check"
                    />
                    <span className={`${strengthRules[ruleKey] ? 'text-blue-200' : 'text-gray-400'}`}>
                      {t(`signUpPage.passwordStrengthMessage.${ruleKeyIndex}`)}{' '}
                    </span>
                  </div>
                )
              })}
            </div>
            {/* { Captcha area} */}
            {isNeedCaptcha ? (
              <div className="mb-3 flex flex-row items-center justify-start">
                <Controller
                  name="captchaInput"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Captcha"
                      variant="outlined"
                      size="small"
                      sx={{
                        mr: 2
                      }}
                      error={!!errors.captcha}
                      helperText={errors.captcha?.message}
                    />
                  )}
                />
                <Captcha updateCaptchaToken={setCaptchaToken} isReGetCaptcha={isReGetCaptcha} />
              </div>
            ) : (
              ''
            )}
            <button
              className={`btn btn-orange ${isActionLoading ? 'disabled' : ''}`}
              type="submit"
              disabled={isActionLoading}
            >
              {isActionLoading ? (
                <svg
                  className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-r-gray-50 border-t-gray-50"
                  style={{
                    width: '24px',
                    height: '24px'
                  }}
                  viewBox="0 0 32 32"
                ></svg>
              ) : (
                <span>{t('button.signUp.0')}</span>
              )}
            </button>
            <div className="my-3 text-gray-700">
              <Divider>or</Divider>
            </div>
            <button className="mb-6 btn btn-orange btn-ghost" type="button" onClick={handleSignupWithGoogle}>
              <img
                className="mr-2"
                src="https://static.mlytics.com/images/website/google_icon.svg"
                width="24px"
                height="24px"
              />
              {t('button.signUp.1')}
            </button>
            <p className="pb-3 text-center text-gray-400 text-xs">
              <Trans
                i18nKey="signUpPage.termsAndPrivacyText"
                components={CustomTransComponents({ links: links.slice(0, 2) })}
              />
            </p>
          </FormControl>
        </form>
      </React.Fragment>
    </AuthLayout>
  )
}

const SignupPage = ({ setMessageDialogOpen, setErrorMessageI18n }) => {
  const { t } = useTranslation()

  const { screenM } = useBreakpoint()

  const [portalHost, setPortalHost] = useState('')

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      setPortalHost(getPortalHost())
    }
  })

  const SLIDER_SETTINGS = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 1500,
    swipe: false,
    touchMove: false,
    autoplaySpeed: 6000,
    cssEase: 'linear'
  }

  return (
    <React.Fragment>
      <Seo
        seo={{
          metaTitle: 'Sign up | Mlytics',
          metaDescription: 'Sign up on Mlytics to get your free $50 credit.',
          metaUrl: 'https://www.mlytics.com/signup/',
          shareImage: {
            imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
            imageWidth: 1200,
            imageHeight: 630,
            imageType: 'image/jpeg'
          }
        }}
      />
      <div className="h-screen grid grid-cols-1 lg:grid-cols-5 overflow-hidden">
        <div className="z-40 w-screen lg:absolute">
          <div className="flex items-center justify-between">
            <Link to="/" className="shrink-0 text-xl font-medium px-6 py-5">
              <img
                alt="Mlytics"
                src={
                  screenM
                    ? 'https://www.mlytics.com/wp-content/uploads/2022/05/logo_Mlytics_white.svg'
                    : 'https://www.mlytics.com/wp-content/uploads/2021/11/logo_Mlytics_regular.svg'
                }
                height="75"
                width="150"
              />
            </Link>
            <div className="p-3 bg-white">
              <LangSwitcher className="flex h-9 items-center" />
            </div>
          </div>
        </div>

        <div className="h-full col-span-3 overflow-hidden hidden lg:block">
          <SimpleSlider className="slider-h-full fade-slide" settings={SLIDER_SETTINGS}>
            {slideImages.map((item, index) => (
              <div key={index} className="h-full">
                <div
                  style={{
                    minHeight: '100vh',
                    maxHeight: '100%',
                    backgroundImage: `url(${item.imageSrc})`,
                    backgroundRepeat: 'none',
                    backgroundSize: 'cover'
                  }}
                />
              </div>
            ))}
          </SimpleSlider>
        </div>
        <div className="p-6 md:p-10 h-full col-span-2 overflow-scroll">
          <div className="h-full flex flex-col">
            <h1 className="mb-3 text-blue-300 text-center">{t('signUpPage.title')}</h1>
            <h3 className="mb-3 text-blue-300 text-center">{t('signUpPage.subtitle')}</h3>
            <p className="mb-6 text-gray-700 text-center">
              <Trans i18nKey="signUpPage.alreadyHaveAccountText">
                <a className="text-blue-200 underline underline-offset-2 font-bold" href={`${portalHost}/login`}></a>
              </Trans>
            </p>
            <SignupForm actionProp={{ setMessageDialogOpen, setErrorMessageI18n }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

SignupForm.propTypes = {
  actionProp: PropTypes.object
}

SignupPage.propTypes = {
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(SignupPage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
