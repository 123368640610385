import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Icon from 'components/icon'
import { connect } from 'react-redux'

const captchaComponent = ({
  updateCaptchaToken = () => {},
  isReGetCaptcha = false,
  setMessageDialogOpen,
  setErrorMessageI18n
}) => {
  const { i18n } = useTranslation()
  const [captchaImage, setCaptchaImage] = useState('')

  const generateCaptchaImage = async () => {
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL
      const response = await fetch(`${apiUrl}/captcha/v1/`, {
        method: 'GET'
      })

      if (!response.ok) {
        throw new Error(`Failed to generate captcha: ${response.status}`)
      }

      const json = await response.json()
      setCaptchaImage(json.captcha)
      updateCaptchaToken(json.captchaToken)
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.getCaptcha'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
    }
  }
  useEffect(() => {
    generateCaptchaImage()
  }, [])

  useEffect(() => {
    generateCaptchaImage()
  }, [isReGetCaptcha])

  return (
    <div className="flex flex-row items-center justify-start" style={{ height: 50 }}>
      <div
        dangerouslySetInnerHTML={{
          __html: captchaImage
        }}
        className="mr-1"
        style={{
          width: 150,
          height: 50
        }}
      ></div>
      <button
        className="p-2 flex flex-row items-center justify-center"
        type="button"
        onClick={generateCaptchaImage}
        style={{
          width: 50,
          height: 50
        }}
      >
        <Icon name="refresh" fontSize="32px" iconStyle="" className="text-gray-700" />
      </button>
    </div>
  )
}
captchaComponent.propTypes = {
  updateCaptchaToken: PropTypes.func,
  isReGetCaptcha: PropTypes.bool,
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(captchaComponent)
